.filter-modal {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    z-index: 10;
}

.filter-modal.show {
    animation: slideIn 0.5s forwards;
}

.filter-modal.hide {
    animation: slideOut 0.5s forwards;
}

.filter-modal-content {
    background-color: #e8e8e8;
    width: 80%;
    max-width: 20rem;
    height: 100%;
    margin-left: auto;
    padding: 1rem;
}

.filter-modal-header {
    display: flex;
    justify-content: flex-end;
}

.filter-modal-close-btn {
    cursor: pointer;
    box-shadow: none;
    background: none;
    border: none;
    font-size: 2rem;
}

.filter-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.filter-options>div {
    margin: 1rem;
}

.filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
}

.filter-button {
    padding: 8px 16px;
    border: none;
    background-color: #f5f5f5;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.filter-button.active {
    background-color: #3d6a9a;
    color: white;
}

@media (max-width: 768px) {

    .filter-modal-content {
        width: 100%;
        max-width: 10rem;
        background-color: #f5f5f5;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .filter-modal-close-btn {
        font-size: 1.5rem;
        background-color: #f5f5f5;
    }

    .filter-options>div {
        margin: 0.5rem;
    }
}