.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    margin-top: 4rem;
    right: 0;
    top: 0;
    gap: 1rem;
    padding: 0.5em;
    cursor: default;
    font-size: 1.5em;
    color: #f5f5f5;
    z-index: 10;
}

.nav-bar .welcome {
    max-width: 500px;
}

.nav-bar .welcome a.nav-link {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home .nav-bar {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    transform: translateY(-100%);
}

.nav-bar.loaded {
    opacity: 1;
    transform: translateY(0);
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 0.5em;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    font-family: "Rubik", sans-serif;
    color: #f5f5f5;
    font-weight: 600;
    letter-spacing: 0.1em;
    transition: color 0.3s ease;
}

.nav-link:hover,
.home .nav-link:hover {
    color: #ffffff;
}

@media (max-width: 768px) {
    .nav-bar {
        justify-content: flex-end;
        white-space: nowrap;
        font-size: 1.2em;
        gap: 0.5rem;
        padding-right: 0;
    }

    .nav-bar .welcome {
        max-width: 125px;
    }

    .home .nav-bar {
        padding: 0.5em 0.5em;
        margin-top: 0;
        top: 10rem;
        right: unset;
    }

    .trail-page .nav-bar,
    .all-trails .nav-bar,
    .user-profile .nav-bar,
    .login-page .nav-bar {
        gap: 0;
        margin-top: 0.3rem;
        justify-content: end;
        align-items: center;
        padding: 0.5em;
        font-size: 1em;
    }
}