.footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 1em;
}

.footer-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    font-size: 2em;
    margin-top: 1rem;
}

.footer a {
    text-decoration: none;
    cursor: pointer;
}

.footer p {
    text-align: center;
    margin-top: 0;
    font-family: 'Rubik', sans-serif;
    font-size: 1em;
}

.footer-tagline {
    font-size: 1.5em;
}

@media (max-width: 768px) {
    .footer {
        padding: 1em 0;
    }

    .footer-links {
        font-size: 1.5em;
        margin: 0;
        gap: 2rem;
    }

    .footer-links a {
        font-size: 0.8em;
    }

    .footer-tagline {
        font-size: 1em;
    }
}