.home .photo-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 100vh;
    min-height: 90vh;
    max-width: 90%;
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 1rem;
}

.upper-section {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.home-background {
    position: relative;
    width: 100vw;
    height: 800px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.search-container {
    position: relative;
    width: 80%;
    max-width: 30rem;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
}

.home input[type="text"] {
    width: 100%;
    background-color: rgba(230, 230, 230, 0.78);
    border: solid 1px hsla(0, 0%, 100%, 0.818);
    font-weight: 600;
    border-radius: 1.5rem;
    padding: 1.2rem 1.5rem;
    font-size: 1em;
    box-shadow: 0 4px 8px rgba(20, 20, 20, 0.2);
    position: relative;
    z-index: 2;
}

.home input[type="text"]:focus {
    background-color: rgba(250, 250, 250, 0.9);
    outline: none;
}

.search-results,
.no-results {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: center;
    position: absolute;
    top: 100%;
    margin-top: 0.2rem;
    left: 0;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.9);
    transform: translateY(-1%);
}

.scroll-indicator {
    display: flex;
    font-size: 1.5em;
    justify-content: center;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: translateY(0);
        opacity: 0.7;
    }

    50% {
        transform: translateY(5px);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 0.7;
    }
}

.search-results.expanded,
.no-results {
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    height: auto;
    width: 100%;
    border: solid 1px hsl(0, 0%, 50%);
    border-radius: 1.5rem;
    box-shadow: 0 4px 8px rgba(20, 20, 20, 0.2);
    overflow-y: auto;
    max-height: 15rem;
}

.trail-preview,
.no-results {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

.trail-preview:last-of-type {
    border-bottom: none;
}

.trail-preview:hover {
    color: #959595;
}

.trail-preview h2 {
    font-size: 1.2em;
    font-weight: 600;
    color: #454545;
    margin: 0;
    flex: 1;
    text-align: left;
}

.trail-preview p {
    font-size: 0.9em;
    color: #666;
    margin: 0;
    flex-shrink: 0;
    text-align: right;
}

.trail-preview h2,
.trail-preview p {
    margin: 0.1em 0;
}

.home-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110vw;
    border: none;
    padding: 0.3rem;
    font-size: 2.5em;
    font-weight: 400;
    padding-top: 1.5rem;
    background-color: #f5f5f5;
    box-shadow: 0 4px 8px rgba(20, 20, 20, 0.2);
}



.home-trails-section,
.home-reports-section {
    max-width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 1.5rem;
}


.home-trails-container {
    gap: 1em;
    width: 100%;
    padding: 0;
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.home-trails-container>div {
    min-width: calc(50% / 4 - 1rem);
    flex-grow: 1;
    flex-shrink: 0;
}

.trail-card:last-child {
    margin-right: 0;
}

.home-reports-section .reports-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    width: 100%;
}

@media (max-width: 768px) {

    .home .photo-loading {
        height: 80vh;
        max-width: 80%;
    }

    .search-container {
        margin-top: -6.5rem;
    }

    .home-titles {
        padding: 1rem;
        font-size: 1rem;
        justify-content: center;
        font-weight: 600;
    }

    .trail-preview h2 {
        font-size: 1em;
    }

    .trail-preview p {
        font-size: 0.8em;
    }

    .home-background {
        height: 800px;
    }

    .home-trails-section,
    .home-reports-section {
        max-width: 100%;
    }

    .home-trails-container {
        margin: 0;
        padding: 0;
    }

    .home-trails-container>div {
        flex-grow: unset;
        flex-shrink: unset;
        max-width: 100% / 2 - 1rem;
    }

    .home-trails-container,
    .home-reports-section .reports-container {
        padding: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .home .photo-loading {
        height: 90vh;
    }

    .home-trails-section,
    .home-reports-section {
        max-width: 100%;
    }

    .home-trails-container,
    .reports-container {
        margin: 0;
        padding: 1em;
    }

    .search-container {
        width: 70%;
        max-width: 40rem;
        margin-top: -12rem;
    }

    .home input[type="text"] {
        padding: 1.5rem 2rem;
        font-size: 1.5em;
    }

    .trail-preview h2 {
        font-size: 1.5em;
    }

    .home-titles {
        font-size: 2em;
    }
}