.report {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-height: 20rem;
    overflow-y: auto;
    color: rgb(100, 100, 100);
    font-family: 'Rubik', sans-serif;
    background-color: #f5f5f5;
    cursor: default;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 0.5rem 0;
    box-sizing: border-box;
    transition: box-shadow 0.2s ease;
}

.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.2rem;
    font-size: 0.9em;
    color: #646464;
    font-weight: 600;
}

.conditions {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    font-size: 0.9em;
    margin-bottom: 0.5rem;
}

.conditions p,
h2 {
    margin: 0;
}

.report-trail-condition,
.report-road-condition {
    margin: 0;
}

.environment {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0;
    font-size: 1.2em;
}

.report-summary {
    font-size: 1em;
    margin-bottom: 0;
}

.report-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;

}

.report-actions button {
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0;
}

.report-actions button:hover {
    background-color: #f0f0f0;
}

.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.arrow {
    cursor: pointer;
    font-size: 24px;
}

.darken {
    color: #000;
}

.lighten {
    color: #ccc;
}

.arrow.darken:hover {
    color: #555;
}

@media (max-width: 768px) {
    .report {
        padding: 0.5rem;
        font-size: 0.9em;
    }

    .report-header {
        font-size: 0.7em;
    }

    .conditions,
    .report-summary {
        font-size: 0.9em;
    }

    .environment {
        font-size: 1em;
        align-items: flex-start;
    }

    .report-actions button {
        font-size: 0.9em;
    }

    .report-pagination-controls button {
        font-size: 0.9em;
    }
}