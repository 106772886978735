.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 8rem;
    padding: 0 1.5rem;
}

.user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;

}

.user-details h1 {
    font-size: 1.5rem;
    color: #5e5e5e;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-details button {
    max-width: fit-content;
    padding: 0.625rem 1.25rem;
    margin: 0;
    background-color: #3d6a9a;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.user-details button:hover {
    background-color: #0056b3;
}

.profile-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.6rem;
    color: #5e5e5e;
    margin: 0;
    padding: 0;

}

.user-favorites-section {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.favorite-trails-container {
    width: 100%;
    height: 22rem;
    overflow-x: hidden;
}

.no-entries {
    margin: 1rem;
    font-weight: 600;
    padding: 0;
}

.profile-trail-reports {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.profile-trail-reports .reports-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
}

.pagination-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.pagination-buttons button {
    font-size: 1em;
    border: none;
    border-radius: 5px;
    margin: 0;
}


@media (max-width: 768px) {
    .user-profile {
        margin-top: 4rem;
        padding: 0 1rem;
    }

    .user-details {
        padding: 0.5rem;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 0;

    }

    .user-details h1 {
        font-size: 1rem;
    }

    .user-details button {
        padding: 0.5rem 1rem;
        font-size: 0.6rem;
    }

    .profile-heading {
        font-size: 1.2rem;
    }

    .profile-heading:first-of-type {
        margin-top: 1rem;
    }

    .favorite-trails-container {
        height: 16rem;
    }

    .reports-container {
        margin-bottom: 1rem;
    }
}