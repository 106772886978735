.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #f5f5f5;
    width: 50%;
    max-width: 85vw;
    padding: 1rem;
    border-radius: 10px;
}

.toast {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: #9d160c;
    color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 1.25rem auto;
    background-color: #f5f5f5;
    color: #5e5e5e;
    padding: 2rem;
    border-radius: 10px;

}

.form h2 {
    color: #505050;
    margin: 0;
    text-align: center;
    font-size: 1em;
    margin-bottom: 1rem;
}

.form .form-group {
    margin-bottom: 1.25rem;
    width: 100%;
}

.form .form-group label {
    display: block;
    color: #606060;
    font-weight: 600;
    font-size: 1em;
    margin: 0;
}

.form .form-group input {
    color: #606060;

}

.form .form-group input[type="text"],
.form .form-group input[type="date"],
.form .form-group textarea {
    width: 100%;
    padding: 0.5rem 0.75rem;

    border: 1px solid #ccc;
    border-radius: 0.25rem;

    box-sizing: border-box;
    font-size: 1rem;
}

.form .form-group textarea {
    resize: vertical;
}

.form .checkbox-group {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 1rem;

}

.form .checkbox-group input[type="checkbox"] {
    margin-right: 0.5rem;

}

.form .checkbox-group .icon {
    margin-right: 0.2rem;

}

.form button[type="submit"],
.form button[type="button"] {
    color: #f5f5f5;
    width: 100%;
    text-align: center;
    padding: 0.75rem 0.5rem;

    border: none;
    border-radius: 0.25rem;

    cursor: pointer;
    font-size: 0.7rem;

}

.form button[type="button"] {
    background-color: #6c757d;
    margin-left: 0.5rem;
    width: 100%;
}

.form button[type="submit"] {
    background-color: #007bff;
}

.form button[type="submit"]:hover {
    background-color: #0056b3;
}

.form button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.form .error {
    color: red;
    margin-top: 0.3125rem;
}

@media (max-width: 768px) {

    .modal-content {
        width: 100%;
        padding: 1rem;
        max-height: min-content;
    }

    .form {
        padding: 0.5rem;
    }

    .form h2 {
        font-size: 1em;
    }

    .form .form-group label {
        font-size: 1em;
    }

    .form .form-group input[type="text"],
    .form .form-group input[type="date"],
    .form .form-group textarea {
        font-size: 1em;
    }

    .form button[type="submit"],
    .form button[type="button"] {
        font-size: 0.7em;
        padding: 0.5rem 0.75rem;
    }

    .form .error {
        font-size: 1.2em;
    }
}