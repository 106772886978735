.all-trails {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12rem;
    padding: 0 2rem;
    width: 100%;
    margin-bottom: 2rem;
}

.trail-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.all-trails-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
    width: 100%;
}

.search-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
    width: 100%;
}

.search-bar-icon {
    position: relative;
    display: flex;
    flex-direction: row;
}

.filters-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-filters-icon {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    cursor: pointer;
    margin-left: 0.5rem;
}

.all-trails .search-bar {
    border: 1px solid #ddd;
    width: 40vw;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem 1rem;
    font-size: 1em;
}

.all-trails .search-bar:focus {
    border-color: #f9f9f9;
    outline: none;
}

@media (max-width: 768px) {
    .all-trails {
        margin-top: 5rem;
        padding: 0 1rem;

    }

    .filters-wrapper {
        padding: 0 1rem;
        flex-direction: column;
        align-items: center;
    }

    .all-trails-section {
        gap: 1rem;
    }

    .search-section {
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem 0;
        margin-bottom: 2rem;
    }

    .all-trails .search-bar {
        width: 80vw;
        padding: 0.75rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .all-trails .search-bar {
        width: 70vw;
    }
}