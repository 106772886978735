.trail-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12rem;
    padding: 0 1.5rem;
}

.trail-information {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.5rem;
}

.trail-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.62rem;
}

.trail-heading h1 {
    font-size: 2.5rem;
    color: #5e5e5e;
    margin: 0;
}

.trail-heading p {
    font-size: 1.25rem;
    color: #5e5e5e;
    margin: 0;
}

.edit-trail-button {
    padding: 0.5rem 1rem;
    margin: 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 1rem;
}

.trail-stats {
    display: flex;
    flex-wrap: wrap;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 9.3rem;
}

.stat-label {
    font-size: 1.2rem;
    font-weight: 600;
}

.stat-value {
    margin: 0;
    font-size: 1rem;
}

.trail-content {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.trail-visuals {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 1.2rem;
    max-width: 500px;
    width: 50%;
}

img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.favorite-toggle {
    position: absolute;
    top: 0rem;
    right: 0rem;
    z-index: 10;
}

.favorite-toggle button {
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 2rem;
    box-shadow: none;
    background-color: #f5f5f5f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #5e5e5ebb;
    border-radius: 0.5rem;
}

.trail-summary {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.dogs-pass {
    display: flex;
    gap: 2rem;
    color: #5e5e5e;

}

.dogs-pass p {
    margin: 0;
    font-weight: 600;
}

.external-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.directions-button,
.weather-button {
    background-color: #4CAF50;
    width: fit-content;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    font-size: 1em;
    color: #f5f5f5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.weather-button {
    background-color: #3d7e9a;
}

.trailpage-reports-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;
    padding: 1rem, 0;
}

.trailpage-reports-section h1 {
    font-size: 1.5rem;
    color: #5e5e5e;
    margin: 0;
}

.trailpage-reports-section p {
    font-size: 1rem;
    margin: 0.2rem 0;
}

.new-report-button {
    width: fit-content;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    font-size: 1em;
    background-color: #4CAF50;
    color: #f5f5f5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.trailpage-reports-section .reports-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
}

@media (max-width: 48rem) {

    .trail-page {
        margin-top: 2rem;
        padding: 0 1rem;
    }

    .trail-heading {
        margin-top: 4rem;
        word-wrap: break-word;
        text-align: center;
    }

    .trail-heading h1 {
        font-size: 1.5rem;
    }

    .trail-heading p {
        font-size: 1rem;
    }

    .trail-information {
        flex-direction: column;
        align-items: center;
    }

    .trail-stats {
        justify-content: space-evenly;
        font-size: 0.9rem;
    }

    .stat-item {
        align-items: center;
    }

    .stat-label {
        font-size: 0.8rem;
    }

    .stat-value {
        font-size: 0.7rem;
    }

    .trail-content {
        flex-direction: column;
        align-items: center;
    }

    .favorite-toggle button {
        font-size: 1.5rem;
    }

    .directions-button,
    .weather-button {
        padding: 0.5rem 1rem;
        font-size: 0.6em;
    }

    .trail-visuals,
    .trail-summary {
        width: 100%;
    }

    .trail-summary {
        margin-top: 1rem;
        font-size: 0.9rem;
    }

    .dogs-pass {
        flex-direction: column;
        align-items: center;
    }

    .dogs-pass p {
        font-size: 0.9rem;
    }

    .trailpage-reports-section p {
        font-size: 0.9rem;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {


    .trail-summary {
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin: auto;
    }

    .trail-visuals {
        width: 100%;
    }

    .trailpage-reports-section {
        margin-top: 1rem;
    }

    .trail-information {
        align-items: center;
    }

}