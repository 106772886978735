.home header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: fit-content;
    padding: 2em 1.5em 0.5em 1.5em;
    margin-bottom: 1em;
    z-index: 10;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 0;
    transform: translateY(-100%);
}

.trail-page header,
.all-trails header,
.user-profile header,
.login-page header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    margin-bottom: 1rem;
    padding: 2em 1.5em 0.5em 1.5em;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 1s ease forwards;
}

header.loaded {
    opacity: 1;
    transform: translateY(0);

}

.home header h1.title {
    display: flex;
    align-items: center;
    color: #f5f5f5;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin: 0;
    font: 400 4em/1 "Josefin Sans", sans-serif;
}

.home .title-part {
    font-size: 1.3em;
}

.trail-page header h1.title,
.all-trails header h1.title,
.user-profile header h1.title,
.login-page header h1.title {
    display: flex;
    align-items: center;
    color: #f5f5f5;
    cursor: pointer;
    margin: 0;
    font: 400 3em/1 "Josefin Sans", sans-serif
}

header nav {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {

    .home header {
        padding: 1em 1em;
        left: unset;

    }

    .home header h1.title {
        margin-top: 5%;
        margin-bottom: auto;
        font-size: 4em;
    }

    .trail-page header h1.title,
    .all-trails header h1.title,
    .user-profile header h1.title,
    .login-page header h1.title {
        font-size: 2em;
    }

    .trail-page header,
    .all-trails header,
    .user-profile header,
    .login-page header {
        padding: 0 1em;
        justify-content: space-between;
    }
}