html,
body {
    margin: 0;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    overflow-x: hidden;

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    font-family: 'Rubik',
    sans-serif;
}

:root {
    line-height: 1.5;
    font-weight: 400;
    color: rgba(140, 140, 140, 0.87);
    background-color: rgb(235, 235, 235);
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        right: -100%;
    }

    to {
        right: 0;
    }
}

@keyframes slideOut {
    from {
        right: 0;
    }

    to {
        right: -100%;
    }
}

.home-loader {
    --r1: 154%;
    --r2: 68.5%;
    width: 25rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
        radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
        radial-gradient(var(--r1) var(--r2) at bottom, #269af2 79.5%, #0000 80%),
        radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%),
        #ccc;
    background-size: 50.5% 220%;
    background-position: -100% 0%, 0% 0%, 100% 0%;
    background-repeat: no-repeat;
    animation: l9 2s infinite linear;
}

@keyframes l9 {
    33% {
        background-position: 0% 33%, 100% 33%, 200% 33%
    }

    66% {
        background-position: -100% 66%, 0% 66%, 100% 66%
    }

    100% {
        background-position: 0% 100%, 100% 100%, 200% 100%
    }
}

.hidden {
    display: none
}



.error-messages {
    color: red;
    box-sizing: border-box
}

input[type="text"],
input[type="password"],
select,
textarea {
    width: 12em;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 0.5em 1em;
    border: solid 1px hsl(104, 10%, 80%);
    border-radius: 0.5em;
    font-size: 1em;
}

form button {
    font-size: 1em;
}

fieldset {
    border: none;
    padding: 0;
    margin-bottom: 1em;

    display: flex;
    gap: 0.5em;
}

textarea {
    width: 24.5em;
    height: 6em;
    font-family: "Cabin", sans-serif;
}

a {
    text-decoration: none;
    background-color: transparent;
    outline: none
}

a,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: inherit
}

button {
    padding: 0.1rem 1.25rem;
    font-family: inherit;
    font-weight: 600;
    color: #808080;
    cursor: pointer;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 0.3rem;
    box-shadow: 0 0.12rem 0.25rem rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

button:focus,
button:focus-visible,
button:active {
    outline: none;
    box-shadow: none
}