.logo {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 1.5;
    stroke: rgb(245, 245, 245);
    stroke-width: 10;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
    width: 2em;
    height: 2em;
    margin: 0;
    cursor: pointer;
}

.trail-page .logo,
.all-trails .logo,
.user-profile .logo,
.login-page .logo {
    filter: unset;
}