.trail-card {
    position: relative;
    background-color: #f5f5f5;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 12rem;
    width: 100%;
    height: 20rem;
    cursor: default;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;

}

.trail-card .trail-image {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.image-placeholder {
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trail-card-info {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0.5rem;
    margin-top: 0.5rem;
}

.trail-card h2 {
    font-size: 1.2em;
    color: #454545;
    margin: 0;
}

.trail-card p {
    font-size: 0.9em;
    color: #666;
    margin: 0;
}

.trail-card h2,
.trail-card p {
    word-wrap: break-word;
}

.trail-card-overlay {
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
}

.trail-card-overlay button {
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.4s ease;
}

.image-placeholder {
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader {
    width: 40px;
    aspect-ratio: 1;
    --c: linear-gradient(#000 0 0);
    --r1: radial-gradient(farthest-side at bottom, #000 93%, #0000);
    --r2: radial-gradient(farthest-side at top, #000 93%, #0000);
    background:
        var(--c), var(--r1), var(--r2),
        var(--c), var(--r1), var(--r2),
        var(--c), var(--r1), var(--r2);
    background-repeat: no-repeat;
    animation: l2 1s linear infinite;
}

@keyframes l2 {

    0%,
    25% {
        background-size: 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
        background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%, 50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
    }

    50% {
        background-size: 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
        background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
    }

    75% {
        background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
        background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(0% - 2px), 50% calc(100% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
    }

    95%,
    100% {
        background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px;
        background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(0% - 2px), 50% calc(100% + 2px), 100% 50%, 100% calc(0% - 2px), 100% calc(100% + 2px);
    }
}

@media (max-width: 768px) {
    .trail-card {
        height: 15rem;
        padding: 0.5rem;
        justify-content: center;
        flex: 1 1 calc(100% / 2 - 0.5rem);
    }


    .trail-card h2 {
        font-size: 1em;
    }

    .trail-card p {
        font-size: 0.8em;
        margin-top: 0.5em;
    }
}