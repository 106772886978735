@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100vw;
    height: 800px;
    position: relative;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 400px;
}

.login-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;

}

.toggle-form {
    display: flex;
    padding: 1rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    background: none;
    box-shadow: none;

}

.login-container .show-signup .user-signup-enter,
.login-container .show-login .user-login-enter {
    animation: fadeIn 1s ease forwards;
}

.login-container .show-signup .user-login-exit,
.login-container .show-login .user-signup-exit {
    animation: fadeOut 1s ease forwards;
}

.credentials-form {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 2em;
    border-radius: 0.5em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.credentials-form h2 {
    font-size: 1rem;
    color: #5e5e5e;
    margin: 0;
    padding: 0 1rem;
}

.credentials-form input[type="text"],
.credentials-form input[type="password"],
.credentials-form input[type="submit"] {
    padding: 0.5em 1em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
    font-size: 0.8rem;
}

.credentials-form input[type="submit"] {
    max-width: fit-content;
    padding: 0.75em 2em;
    background-color: #3d6a9a;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.credentials-form input[type="submit"]:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {

    .login-container {
        justify-content: center;
        gap: 1rem;
    }

    .credentials-form {
        padding: 1rem;
    }

    .credentials-form h2 {
        font-size: 1em;
    }

    .credentials-form input[type="text"],
    .credentials-form input[type="password"],
    .credentials-form input[type="submit"] {
        font-size: 0.8em;
    }

    .login-page .error {
        font-size: 0.5rem;
        margin-top: 0.5em;
    }

    .toggle-form {
        font-size: 0.8rem;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .credentials-form {
        padding: 1rem;
    }

    .credentials-form h2 {
        font-size: 1.5em;
    }

    .credentials-form input[type="text"],
    .credentials-form input[type="password"],
    .credentials-form input[type="submit"] {
        font-size: 1em;
    }

    .login-page .error {
        font-size: 0.5rem;
        margin-top: 0.5em;
    }

    .toggle-form {
        font-size: 1rem;
    }
}